@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Light'),
  local('Gilroy-Light'),
  url('/assets/fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Light.woff') format('woff');
  font-display: block;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Regular'),
  local('Gilroy-Regular'),
  url('/assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-display: block;
}

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Semibold'),
  local('Gilroy-Semibold'),
  url('/assets/fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Semibold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Bold'),
  local('Gilroy-Bold'),
  url('/assets/fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-weight: 800;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Extra Bold'),
  local('Gilroy-Extra-Bold'),
  url('/assets/fonts/Gilroy/Gilroy-Extra-Bold.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Extra-Bold.woff') format('woff');
  font-display: block;
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: Gilroy;
  src: local('Gilroy Heavy'),
  local('Gilroy-Heavy'),
  url('/assets/fonts/Gilroy/Gilroy-Heavy.woff2') format('woff2'),
  url('/assets/fonts/Gilroy/Gilroy-Heavy.woff') format('woff');
  font-display: block;
}
