:root {
  --system-font: Gilroy, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  --mono-font: Menlo, ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Monaco, 'Lucida Console', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace;
  --letter-spacing: 0.03rem;

  --color-primary: rgb(146, 101, 194);
  --color-text: #333;
  --color-background: #fff;
  --color-formBorder: #707070;
  --color-feminine: #c2be33;
  --color-masculine: #ffa600;

  --animationLength: 300ms;

  --gap-general: 2rem;
  --gap-mobile: 2rem;
  --gap-tablet: 3rem;
  --gap-desktop: 4rem;
}

@media (prefers-reduced-motion) {
  :root {
    --animationLength: 0ms;
  }
}
