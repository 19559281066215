@import 'variables.css';
@import 'fonts.css';
@import 'root.css';

/**
consider p3
color(display-p3 0.0326 0.8649 0.0129),
color(display-p3 0.0633 0.5735 0.946),
color(display-p3 0.9967 0.0368 0.5605),
color(display-p3 0.9942 0.6104 0.0092)
 */

*,
*::before,
*::after {
  box-sizing: border-box;
  border-color: currentColor;
}

[hidden],
.hidden {
  display: none;
}

html {
  font-size: 62.5%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  font-family: var(--system-font);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: var(--letter-spacing);
  text-rendering: optimizeSpeed;
  min-height: 100vh;
  color: var(--color-text);
  background-color: var(--color-background);
}

html,
body,
:--headers,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: var(--gap-general) 0 0;
}

code {
  font-family: var(--mono-font);
}

small {
  font-size: 80%;
}

a {
  color: inherit;
  text-decoration: inherit;
}

img,
picture {
  max-width: 100%;
  display: block;
}

button,
input,
textarea,
select,
optgroup {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 1rem;
  text-transform: none;
  border-color: var(--color-formBorder);
  border-width: 1px;
  border-style: solid;
  letter-spacing: var(--letter-spacing);
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}
/**
import global styles here like grid systems
 */

:--headers {
  font-family: var(--system-font);
}

h1 {
  font-size: 3rem;

  @media (--tabletLgAndAbove) {
    font-size: 4rem;
  }
}

h2 {
  color: var(--color-primary);
  padding-bottom: 2rem;
}

button,
.btn {
  color: #fff;
  background-color: var(--color-primary);
  background-image: none;
  appearance: none;
  font-family: inherit;
  font-weight: 600;
  border: none;
  border-radius: .3rem;
  line-height: 1;
  padding: 1rem 2rem;

  &:hover {
    background-color: #7952A2;
  }
  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.pre {
  white-space: pre;
}

.masculine {
  background-color: var(--color-masculine);
}

.feminine {
  background-color: var(--color-feminine);
}

.gapBase {
  padding-bottom: var(--gap-mobile);

  @media (--tabletAndAbove) {
    padding-bottom: var(--gap-tablet);
  }

  @media (--tabletLgAndAbove) {
    padding-bottom: var(--gap-desktop);
  }
}

.padBase {
  padding-bottom: var(--gap-general);
}
